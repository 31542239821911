import React from "react";
import { useTranslation } from "react-i18next";
import { DiscountCardContainer, DeleteIcon } from "./style";
import { Text } from "helpers/generalStyles";
import SelectActivities from "./SelectActivities";
import { DiscountWithActivities } from "./DiscountList";
import RemoveDiscountModal from "./RemoveDiscountModal";

interface Props {
  discount: DiscountWithActivities;
}

const DiscountCard = ({ discount }: Props) => {
  const { t } = useTranslation("discounts");
  const [isRemoveModalDisplayed, setIsRemoveModalDisplayed] =
    React.useState<boolean>(false);

  return (
    <DiscountCardContainer>
      {isRemoveModalDisplayed && (
        <RemoveDiscountModal
          discount={discount}
          handleClose={() => setIsRemoveModalDisplayed(false)}
          t={t}
        />
      )}
      <DeleteIcon onClick={() => setIsRemoveModalDisplayed(true)} />
      <Text size="22px" weight={700}>
        {discount.name}
      </Text>
      <Text margin="8px 0 20px 0" size="18px">
        -{discount.discountNumber}% | code : {discount.code}
      </Text>
      <SelectActivities
        selectedActivityIds={discount.activities.map((activity) =>
          activity.id.toString(),
        )}
        activities={discount.activities}
        handleSelectChange={() => {}}
        t={t}
        id={"activity_id" + discount.id}
      />
    </DiscountCardContainer>
  );
};

export default DiscountCard;
