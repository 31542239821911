import { Modal, Button } from "@mui/material";
import {
  ModalStyle,
  MainTitleText,
  FourthTitleText,
} from "../../../helpers/generalStyles";
import { Booking, EventData } from "../types";
import moment from "moment";
import BookingCard, { BookingCardContent } from "../helpers/BookingCard";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import {
  BookingStatusEnum,
  categoryImageMapping,
  PlatformTypeEnum,
  ActivityTypeEnum,
} from "../../../helpers/constants";
import { useMemo, useState } from "react";
import { useMutation, useQuery } from "react-query";
import {
  getBookings,
  putBookingAndPaidStatus,
} from "../../../backend/api/bookings";
import { queryClient } from "../../..";
import { isSlotBookable, statusColorMapping } from "../helpers/utis";
import { applyStyleToTranslation, Message } from "../../../helpers/helpers";
import AlertMessage from "../../general/AlertMessage";

export function AgendaEventUpdateModal(props: {
  event: EventData;
  setSelectedEvent: any;
  setSelectedBooking: any;
  t: any;
}) {
  const { event, setSelectedEvent, setSelectedBooking, t } = props;
  const activityId = event.data.activityId;
  const from = moment(event.start).format("YYYY-MM-DD HH:mm");
  const to = moment(event.end).format("YYYY-MM-DD HH:mm");
  const [selectedBookingForConfirmation, setSelectedBookingForConfirmation] =
    useState<Booking | null>(null);
  // const [selectedBookingForUpdate, setSelectedBookingForUpdate] = useState<Booking | NewBooking | null>(null)
  const [selectedBookingForCancel, setSelectedBookingForCancel] =
    useState<Booking | null>(null);
  const [selectedBookingForPaid, setSelectedBookingForPaid] =
    useState<Booking | null>(null);

  const [bookings, setBookings] = useState<Booking[]>([]);
  const [alertMessage, setAlertMessage] = useState<Message>({
    type: "success",
    message: "",
  });

  // we have nbOfPeopleOnSlot on each elements of the bookings list calculate in the backend
  const nbOfPeopleOnSlot =
    bookings.length > 0 ? bookings[0].numberOfUnitOnSlot : 0;
  const momentStartDate = moment(event?.start);
  useQuery({
    queryKey: ["getBookings", activityId, from, to],
    queryFn: () => getBookings(activityId, from, to),
    // staleTime: 60000,
    refetchOnWindowFocus: false,
    onSuccess(data) {
      setBookings(data);
    },
  });

  const mutatePutBookingStatus = useMutation({
    mutationFn: (data: {
      bookingIds: number[];
      status: BookingStatusEnum;
      paid?: boolean;
    }) => putBookingAndPaidStatus(data.bookingIds, data.status, data.paid),
    onSuccess: (result) => {
      queryClient.invalidateQueries({
        queryKey: ["getActivitiesAvailabilities"],
      });
      queryClient.invalidateQueries({ queryKey: ["getBookings"] });
      setAlertMessage({
        type: "success",
        message: t("success.updateBookingStatusMessage"),
      });
    },
    onError: (error: any) => {
      console.log(error);
      setAlertMessage({
        type: "error",
        message: t(error?.response?.data?.error),
      });
    },
  });

  const updateBookingStatus = (
    bookingIds: number[],
    status: BookingStatusEnum,
    paid?: boolean,
  ) => {
    mutatePutBookingStatus.mutate({ bookingIds, status, paid });
  };

  return (
    <Modal
      open={event !== null}
      onClose={() => setSelectedEvent(null)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalStyle>
        <AlertMessage
          alertMessage={alertMessage.message}
          setAlertMessage={setAlertMessage}
          type={alertMessage.type}
          t={t}
        />
        <AgendaEventBookingConfirmationModal
          event={event}
          selectedBooking={selectedBookingForConfirmation}
          setSelectedBooking={setSelectedBookingForConfirmation}
          updateBookingStatus={updateBookingStatus}
          t={t}
        />
        <AgendaEventBookingCancelationModal
          event={event}
          selectedBooking={selectedBookingForCancel}
          setSelectedBooking={setSelectedBookingForCancel}
          updateBookingStatus={updateBookingStatus}
          t={t}
        />
        <AgendaEventBookingPaidModal
          event={event}
          selectedBooking={selectedBookingForPaid}
          setSelectedBooking={setSelectedBookingForPaid}
          updateBookingStatus={updateBookingStatus}
          t={t}
        />
        {/* #TODO: reactivate when we will authorize update booking */}
        {/* {
            selectedBookingForUpdate ? (
              <AgendaEventBookingAddUpdateModal selectedBooking={selectedBookingForUpdate} setSelectedBooking={setSelectedBookingForUpdate} updateBookingStatus={updateBookingStatus} setAlertMessage={setAlertMessage} t={t} />
            ) : null
          } */}
        <MainTitleText>{t("updateBookingsModal.myBookings")}</MainTitleText>
        <div style={{ display: "flex", alignItems: "center" }}>
          {categoryImageMapping(event.data.category, "#4FD1C5")}
          <FourthTitleText>{event.data.title}</FourthTitleText>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <EventAvailableIcon style={{ color: "#4FD1C5" }} />
          <FourthTitleText>{`${momentStartDate.format("DD-MMMM-YYYY")} | ${momentStartDate.format("HH:mm")} - ${nbOfPeopleOnSlot} / ${event.data.maxCapacity}`}</FourthTitleText>
        </div>

        {bookings.length !== 0 ? (
          <div
            style={{
              width: "100%",
              padding: "20px",
              display: "flex",
              flexDirection: "column",
              gap: "15px",
              marginTop: "20px",
              maxHeight: "300px",
              overflowY: "scroll",
            }}
          >
            {bookings.map((item) => (
              <BookingCard
                booking={item}
                setSelectedBookingForConfirmation={
                  setSelectedBookingForConfirmation
                }
                setSelectedBookingForCancel={setSelectedBookingForCancel}
                setSelectedBookingForUpdate={setSelectedBooking}
                setSelectedBookingForPaid={setSelectedBookingForPaid}
                t={t}
              />
            ))}
          </div>
        ) : (
          <FourthTitleText style={{ color: "lightgray", fontWeight: 600 }}>
            {t("updateBookingsModal.noBookingsYetOnThisActivity")}
          </FourthTitleText>
        )}
        {isSlotBookable(event.data.specialSlotType) ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "10px",
              marginTop: "20px",
              cursor: "pointer",
            }}
            onClick={() =>
              setSelectedBooking({
                activityId: event.data.activityId,
                start: moment(event.start).format("YYYY-MM-DD HH:mm"),
                end: moment(event.end).format("YYYY-MM-DD HH:mm"),
              })
            }
          >
            <AddCircleOutlineIcon color="secondary" />
            <FourthTitleText>
              {t("updateBookingsModal.newBooking")}
            </FourthTitleText>
          </div>
        ) : null}
      </ModalStyle>
    </Modal>
  );
}

export function AgendaEventBookingConfirmationModal(props: {
  event: EventData;
  selectedBooking: Booking | null;
  setSelectedBooking: any;
  updateBookingStatus: any;
  t: any;
}) {
  const { event, selectedBooking, setSelectedBooking, updateBookingStatus, t } =
    props;
  const momentStartDate = moment(event?.start);
  const bookingIds = selectedBooking?.tickets.map((ticket) => ticket.bookingId);
  const color =
    selectedBooking && selectedBooking.status === BookingStatusEnum.DONE
      ? "white"
      : "inherit";
  const backgroundColor = selectedBooking
    ? statusColorMapping[selectedBooking.status]
    : "#4FD1C5";
  const maxUnits = useMemo(() => {
    if (selectedBooking) {
      const { maxCapacity, maxGroups, activityType } = selectedBooking;
      if (activityType === ActivityTypeEnum.INDIVIDUAL) return maxCapacity;
      else if (activityType === ActivityTypeEnum.GROUP) return maxGroups;
    }
  }, [selectedBooking]);

  return (
    <Modal
      open={selectedBooking !== null}
      onClose={() => setSelectedBooking(null)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      {selectedBooking !== null ? (
        <ModalStyle>
          <MainTitleText>
            {t("changeBookingStatusModal.confirmCheckIn")}
          </MainTitleText>
          <div style={{ display: "flex", alignItems: "center" }}>
            {categoryImageMapping(selectedBooking.category, "#4FD1C5")}
            <FourthTitleText>{t(selectedBooking.category)}</FourthTitleText>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <EventAvailableIcon style={{ color: "#4FD1C5" }} />
            <FourthTitleText>{`${momentStartDate.format("DD-MMMM-YYYY")} | ${momentStartDate.format("HH:mm")} - ${selectedBooking.numberOfUnitOnSlot} / ${maxUnits}`}</FourthTitleText>
          </div>
          <div
            style={{
              backgroundColor: backgroundColor,
              color: color,
              position: "relative",
              height: "97px",
              width: "706px",
              borderRadius: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <BookingCardContent booking={selectedBooking} t={t} />
          </div>
          <FourthTitleText>
            {t("changeBookingStatusModal.beCarefulConfirmation")}
          </FourthTitleText>
          <div style={{ display: "flex", gap: "20px", width: "50%" }}>
            <Button
              onClick={() => setSelectedBooking(null)}
              fullWidth={true}
              variant="contained"
              type="button"
            >
              {t("back")}
            </Button>
            {selectedBooking.status !== BookingStatusEnum.NO_SHOW ? (
              <Button
                onClick={() => {
                  updateBookingStatus(bookingIds, BookingStatusEnum.NO_SHOW);
                  setSelectedBooking(null);
                }}
                fullWidth={true}
                variant="contained"
                type="button"
              >
                {t("noShow")}
              </Button>
            ) : null}
            <Button
              onClick={() => {
                updateBookingStatus(bookingIds, BookingStatusEnum.DONE);
                setSelectedBooking(null);
              }}
              fullWidth={true}
              variant="contained"
              type="button"
            >
              {t("confirm")}
            </Button>
          </div>
        </ModalStyle>
      ) : (
        <ModalStyle>{t("emptySelectedEventMessage")}</ModalStyle>
      )}
    </Modal>
  );
}

export function AgendaEventBookingCancelationModal(props: {
  event: EventData;
  selectedBooking: Booking | null;
  setSelectedBooking: any;
  updateBookingStatus: any;
  t: any;
}) {
  const { event, selectedBooking, setSelectedBooking, updateBookingStatus, t } =
    props;
  const momentStartDate = moment(event?.start);
  const bookingIds = selectedBooking?.tickets.map((ticket) => ticket.bookingId);
  const color =
    selectedBooking && selectedBooking.status === BookingStatusEnum.DONE
      ? "white"
      : "inherit";
  const backgroundColor = selectedBooking
    ? statusColorMapping[selectedBooking.status]
    : "#4FD1C5";

  const getCancelBookingComplementaryTradElems = (booking: Booking) => {
    let tradElems = { key: "", params: {}, link: "" };
    if (booking.paid) {
      if (booking.platformType === PlatformTypeEnum.WIDGET) {
        tradElems["key"] =
          "confirmationModal.cancelConfirmationModalExplainationsPaymentDoneFromWidget";
        tradElems["params"] = {
          email: booking.email,
          orderId: booking.orderId,
        };
        tradElems["link"] =
          "https://www.loom.com/share/dab9ef59e19e4331ac752d8dc4e7f99a";
      } else if (booking.platformType === PlatformTypeEnum.HOPLEISURE) {
        tradElems["key"] =
          "confirmationModal.cancelConfirmationModalExplainationsPaymentDoneFromHopleisure";
      } else {
        tradElems["key"] =
          "confirmationModal.cancelConfirmationModalExplainationsPaymentDoneFromAPlatform";
        tradElems["params"] = { platformName: booking.platformName };
      }
    }
    return tradElems;
  };

  const displayCancelBookingComplementaryText = () => {
    if (!selectedBooking) return null;
    const tradElems = getCancelBookingComplementaryTradElems(selectedBooking);
    return applyStyleToTranslation(tradElems, "agenda");
  };
  const maxUnits = useMemo(() => {
    if (selectedBooking) {
      const { maxCapacity, maxGroups, activityType } = selectedBooking;
      if (activityType === ActivityTypeEnum.INDIVIDUAL) return maxCapacity;
      else if (activityType === ActivityTypeEnum.GROUP) return maxGroups;
    }
  }, [selectedBooking]);

  return (
    <Modal
      open={selectedBooking !== null}
      onClose={() => setSelectedBooking(null)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      {selectedBooking !== null ? (
        <ModalStyle>
          <MainTitleText>
            {t("confirmationModal.cancelConfirmationModalTitle")}
          </MainTitleText>
          <div style={{ display: "flex", alignItems: "center" }}>
            {categoryImageMapping(selectedBooking.category, "#4FD1C5")}
            <FourthTitleText>{t(selectedBooking.category)}</FourthTitleText>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <EventAvailableIcon style={{ color: "#4FD1C5" }} />
            <FourthTitleText>{`${momentStartDate.format("DD-MMMM-YYYY")} | ${momentStartDate.format("HH:mm")} - ${selectedBooking.numberOfUnitOnSlot} / ${maxUnits}`}</FourthTitleText>
          </div>
          <FourthTitleText>
            {t("confirmationModal.cancelConfirmationModalExplainations")}
          </FourthTitleText>
          <div
            style={{
              backgroundColor: backgroundColor,
              color: color,
              position: "relative",
              height: "97px",
              width: "706px",
              borderRadius: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <BookingCardContent booking={selectedBooking} t={t} />
          </div>
          <div>{displayCancelBookingComplementaryText()}</div>
          <div style={{ display: "flex", gap: "20px", width: "50%" }}>
            <Button
              onClick={() => setSelectedBooking(null)}
              fullWidth={true}
              variant="contained"
              type="button"
            >
              {t("back")}
            </Button>
            <Button
              onClick={() => {
                updateBookingStatus(bookingIds, BookingStatusEnum.CANCELED);
                setSelectedBooking(null);
              }}
              fullWidth={true}
              variant="contained"
              type="button"
            >
              {t("confirm")}
            </Button>
          </div>
        </ModalStyle>
      ) : (
        <ModalStyle>{t("emptySelectedEventMessage")}</ModalStyle>
      )}
    </Modal>
  );
}

export function AgendaEventBookingPaidModal(props: {
  event: EventData;
  selectedBooking: Booking | null;
  setSelectedBooking: any;
  updateBookingStatus: any;
  t: any;
}) {
  const { event, selectedBooking, setSelectedBooking, updateBookingStatus, t } =
    props;
  const momentStartDate = moment(event?.start);
  const bookingIds = selectedBooking?.tickets.map((ticket) => ticket.bookingId);
  const color =
    selectedBooking && selectedBooking.status === BookingStatusEnum.DONE
      ? "white"
      : "inherit";
  const backgroundColor = selectedBooking
    ? statusColorMapping[selectedBooking.status]
    : "#4FD1C5";

  const maxUnits = useMemo(() => {
    if (selectedBooking) {
      const { maxCapacity, maxGroups, activityType } = selectedBooking;
      if (activityType === ActivityTypeEnum.INDIVIDUAL) return maxCapacity;
      else if (activityType === ActivityTypeEnum.GROUP) return maxGroups;
    }
  }, [selectedBooking]);

  return (
    <Modal
      open={selectedBooking !== null}
      onClose={() => setSelectedBooking(null)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      {selectedBooking !== null ? (
        <ModalStyle>
          <MainTitleText>
            {t("confirmationModal.bookingPaidTitle")}
          </MainTitleText>
          <div style={{ display: "flex", alignItems: "center" }}>
            {categoryImageMapping(selectedBooking.category, "#4FD1C5")}
            <FourthTitleText>{t(selectedBooking.category)}</FourthTitleText>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <EventAvailableIcon style={{ color: "#4FD1C5" }} />
            <FourthTitleText>{`${momentStartDate.format("DD-MMMM-YYYY")} | ${momentStartDate.format("HH:mm")} - ${selectedBooking.numberOfUnitOnSlot} / ${maxUnits}`}</FourthTitleText>
          </div>
          <div
            style={{
              backgroundColor: backgroundColor,
              color: color,
              position: "relative",
              height: "97px",
              width: "706px",
              borderRadius: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <BookingCardContent booking={selectedBooking} t={t} />
          </div>
          <FourthTitleText>{}</FourthTitleText>
          <div style={{ display: "flex", gap: "20px", width: "50%" }}>
            <Button
              onClick={() => {
                // we call updateBookingStatus here but this will update the paid field only because we send the same status
                updateBookingStatus(bookingIds, selectedBooking.status, false);
                setSelectedBooking(null);
              }}
              fullWidth={true}
              variant="contained"
              type="button"
            >
              {t("no")}
            </Button>
            <Button
              onClick={() => {
                // we call updateBookingStatus here but this will update the paid field only because we send the same status
                updateBookingStatus(bookingIds, selectedBooking.status, true);
                setSelectedBooking(null);
              }}
              fullWidth={true}
              variant="contained"
              type="button"
            >
              {t("yes")}
            </Button>
          </div>
        </ModalStyle>
      ) : (
        <ModalStyle>{t("emptySelectedEventMessage")}</ModalStyle>
      )}
    </Modal>
  );
}
