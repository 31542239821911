import moment from "moment";
import { Booking, EventData } from "../types";
import {
  FourthTitleText,
  MainTitleText,
  ModalStyle,
} from "../../../helpers/generalStyles";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import { useState } from "react";
import { Button, Modal } from "@mui/material";
import AgendaConfirmationModal from "../helpers/AgendaConfirmationModal";
import { useMutation, useQuery } from "react-query";
import { queryClient } from "../../..";
import {
  getBookings,
  putBookingAndPaidStatus,
} from "../../../backend/api/bookings";
import {
  BookingStatusEnum,
  SpecialSlotTypeEnum,
} from "../../../helpers/constants";
import {
  PostSpecialSlotsInput,
  postSpecialSlots,
} from "../../../backend/api/specialSlots";
import { isSlotBookable } from "../helpers/utis";
import styled from "styled-components";

export function AgendaEventChangeEventStatusModal(props: {
  selectedEvent: EventData;
  setSelectedEvent: any;
  setAlertMessage: any;
  t: any;
}) {
  const { selectedEvent, setSelectedEvent, setAlertMessage, t } = props;
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [bookings, setBookings] = useState<Booking[]>([]);
  const momentStartDate = moment(selectedEvent?.start);
  const activityId = selectedEvent.data.activityId;
  const from = moment(selectedEvent.start).format("YYYY-MM-DD HH:mm");
  const to = moment(selectedEvent.end).format("YYYY-MM-DD HH:mm");

  const dayString = moment(selectedEvent.start).format("YYYY-MM-DD");
  const fromTimeString = moment(selectedEvent.start).format("HH:mm");
  const toTimeString = moment(selectedEvent.end).format("HH:mm");

  useQuery({
    queryKey: ["getBookings", activityId, from, to],
    queryFn: () => getBookings(activityId, from, to),
    // staleTime: 60000,
    refetchOnWindowFocus: false,
    onSuccess(data) {
      setBookings(data);
    },
  });

  const mutatePutBookingStatus = useMutation({
    mutationFn: (data: { bookingIds: number[]; status: BookingStatusEnum }) =>
      putBookingAndPaidStatus(data.bookingIds, data.status),
    onSuccess: (result) => {
      queryClient.invalidateQueries({
        queryKey: ["getActivitiesAvailabilities"],
      });
      queryClient.invalidateQueries({ queryKey: ["getBookings"] });
      setAlertMessage({
        type: "success",
        message: t("success.updateBookingStatusMessage"),
      });
    },
    onError: (error: any) => {
      console.log(error);
      setAlertMessage({
        type: "error",
        message: t(`errors.${error?.response?.data?.error}`),
      });
    },
  });

  const updateBookingStatus = (
    bookingIds: number[],
    status: BookingStatusEnum,
  ) => {
    mutatePutBookingStatus.mutate({ bookingIds, status });
  };

  const mutatePostSpecialSlot = useMutation({
    mutationFn: (data: PostSpecialSlotsInput["body"]) => postSpecialSlots(data),
    onSuccess: (result) => {
      queryClient.invalidateQueries({
        queryKey: ["getActivitiesAvailabilities"],
      });
      queryClient.invalidateQueries({ queryKey: ["getBookings"] });
      setSelectedEvent(null);
      setAlertMessage({
        type: "success",
        message: t("success.addSpecialSlotMessage"),
      });
    },
    onError: (error: any) => {
      console.log(error);
      setAlertMessage({
        type: "error",
        message: t(`errors.${error?.response?.data?.error}`),
      });
    },
  });

  const upsertSpecialSlots = (
    type: SpecialSlotTypeEnum | null,
    activityIds: number[],
    days: string[],
    fromTime: string,
    toTime: string,
  ) => {
    mutatePostSpecialSlot.mutate({ type, activityIds, days, fromTime, toTime });
  };

  // get all bookingIds in all bookings of the activity
  const bookingIds: number[] = [];
  for (const booking of bookings) {
    bookingIds.push(...booking.tickets.map((ticket) => ticket.bookingId));
  }

  return (
    <Modal
      open={selectedEvent !== null}
      onClose={() => setSelectedEvent(null)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      {selectedEvent !== null ? (
        <ModalStyle>
          {isSlotBookable(selectedEvent.data.specialSlotType) ? (
            <BookableSlotModal
              openConfirmationModal={openConfirmationModal}
              setOpenConfirmationModal={setOpenConfirmationModal}
              selectedEvent={selectedEvent}
              updateBookingStatus={updateBookingStatus}
              upsertSpecialSlots={upsertSpecialSlots}
              bookingIds={bookingIds}
              activityId={activityId}
              dayString={dayString}
              fromTimeString={fromTimeString}
              toTimeString={toTimeString}
              momentStartDate={momentStartDate}
              t={t}
            />
          ) : (
            <NonBookableSlotModal
              openConfirmationModal={openConfirmationModal}
              setOpenConfirmationModal={setOpenConfirmationModal}
              selectedEvent={selectedEvent}
              upsertSpecialSlots={upsertSpecialSlots}
              activityId={activityId}
              dayString={dayString}
              fromTimeString={fromTimeString}
              toTimeString={toTimeString}
              momentStartDate={momentStartDate}
              t={t}
            />
          )}
        </ModalStyle>
      ) : (
        <ModalStyle>{t("emptySelectedEventMessage")}</ModalStyle>
      )}
    </Modal>
  );
}

function BookableSlotModal(props: {
  openConfirmationModal: boolean;
  setOpenConfirmationModal: any;
  selectedEvent: EventData;
  updateBookingStatus: any;
  upsertSpecialSlots: any;
  bookingIds: number[];
  activityId: number;
  dayString: string;
  fromTimeString: string;
  toTimeString: string;
  momentStartDate: moment.Moment;
  t: any;
}) {
  const {
    openConfirmationModal,
    setOpenConfirmationModal,
    selectedEvent,
    updateBookingStatus,
    upsertSpecialSlots,
    bookingIds,
    activityId,
    dayString,
    fromTimeString,
    toTimeString,
    momentStartDate,
    t,
  } = props;
  return (
    <div>
      <AgendaConfirmationModal
        open={openConfirmationModal}
        setOpen={setOpenConfirmationModal}
        momentStartDate={moment(selectedEvent.start)}
        submitModal={() => {
          updateBookingStatus(bookingIds, BookingStatusEnum.CANCELED);
          upsertSpecialSlots(
            SpecialSlotTypeEnum.UNAVAILABLE,
            [activityId],
            [dayString],
            fromTimeString,
            toTimeString,
          );
        }}
        title={t("changeSlotStatusModal.cancelAndCloseConfirmationModalTitle")}
        explainations={`${t("changeSlotStatusModal.cancelAndCloseConfirmationModalExplainations")} - ${t("changeSlotStatusModal.cancelAndCloseConfirmationModalExplainations2")}`}
        cancelButton={t("cancel")}
        validateButton={t("confirm")}
        t={t}
      />
      <MainTitleText>
        {t("changeSlotStatusModal.closeThisSlotToBookings")}
      </MainTitleText>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <EventAvailableIcon style={{ color: "#4FD1C5" }} />
        <FourthTitleText>{`${momentStartDate.format("DD-MMMM-YYYY")} | ${momentStartDate.format("HH:mm")} - ${selectedEvent.data.numberOfUnitBooked} / ${selectedEvent.data.maxCapacity}`}</FourthTitleText>
      </div>
      <FourthTitleText>
        {t("changeSlotStatusModal.closeThisSlotToBookingsExplainations")}
      </FourthTitleText>

      <SlotModalButtonDiv>
        <Button
          color="warning"
          onClick={() => {
            // we send null in upsertSpecialSlots to cancel the MARK_AS_FULL in db
            if (
              selectedEvent.data.specialSlotType ===
              SpecialSlotTypeEnum.MARK_AS_FULL
            )
              upsertSpecialSlots(
                null,
                [activityId],
                [dayString],
                fromTimeString,
                toTimeString,
              );
            else
              upsertSpecialSlots(
                SpecialSlotTypeEnum.MARK_AS_FULL,
                [activityId],
                [dayString],
                fromTimeString,
                toTimeString,
              );
          }}
          style={{ width: "50%" }}
          variant="contained"
          type="button"
        >
          {t("changeSlotStatusModal.doNotTakeMoreBookings")}
        </Button>
        <Button
          color="info"
          onClick={() => setOpenConfirmationModal(true)}
          style={{ width: "50%" }}
          variant="contained"
          type="button"
        >
          {t("changeSlotStatusModal.closeTheSlotToBookings")}
        </Button>
      </SlotModalButtonDiv>
    </div>
  );
}

function NonBookableSlotModal(props: {
  openConfirmationModal: boolean;
  setOpenConfirmationModal: any;
  selectedEvent: EventData;
  upsertSpecialSlots: any;
  activityId: number;
  dayString: string;
  fromTimeString: string;
  toTimeString: string;
  momentStartDate: moment.Moment;
  t: any;
}) {
  const {
    openConfirmationModal,
    setOpenConfirmationModal,
    selectedEvent,
    upsertSpecialSlots,
    activityId,
    dayString,
    fromTimeString,
    toTimeString,
    momentStartDate,
    t,
  } = props;
  return (
    <div>
      <AgendaConfirmationModal
        open={openConfirmationModal}
        setOpen={setOpenConfirmationModal}
        momentStartDate={moment(selectedEvent.start)}
        submitModal={() => {
          // we send null in upsertSpecialSlots to cancel the MARK_AS_FULL in db
          upsertSpecialSlots(
            null,
            [activityId],
            [dayString],
            fromTimeString,
            toTimeString,
          );
        }}
        title={t("changeSlotStatusModal.reopenConfirmationModalTitle")}
        explainations={t(
          "changeSlotStatusModal.reopenConfirmationModalExplainations",
        )}
        cancelButton={t("cancel")}
        validateButton={t("confirm")}
        t={t}
      />
      <MainTitleText>
        {t("changeSlotStatusModal.reopenThisSlotToBookings")}
      </MainTitleText>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <EventAvailableIcon style={{ color: "#4FD1C5" }} />
        <FourthTitleText>{`${momentStartDate.format("DD-MMMM-YYYY")} | ${momentStartDate.format("HH:mm")} - ${selectedEvent.data.numberOfUnitBooked} / ${selectedEvent.data.maxCapacity}`}</FourthTitleText>
      </div>
      <FourthTitleText>
        {t("changeSlotStatusModal.reopenThisSlotToBookingsExplainations")}
      </FourthTitleText>

      <SlotModalButtonDiv>
        <Button
          color="warning"
          onClick={() => {
            // we send null in upsertSpecialSlots to cancel the MARK_AS_FULL in db
            upsertSpecialSlots(
              null,
              [activityId],
              [dayString],
              fromTimeString,
              toTimeString,
            );
          }}
          style={{ width: "50%" }}
          variant="contained"
          type="button"
          disabled={
            selectedEvent.data.specialSlotType !==
            SpecialSlotTypeEnum.MARK_AS_FULL
          }
        >
          {t("changeSlotStatusModal.retakeBookings")}
        </Button>
        <Button
          color="info"
          onClick={() => {
            setOpenConfirmationModal(true);
          }}
          style={{ width: "50%" }}
          variant="contained"
          type="button"
          disabled={
            selectedEvent.data.specialSlotType !==
            SpecialSlotTypeEnum.UNAVAILABLE
          }
        >
          {t("changeSlotStatusModal.reopenTheSlotToBookings")}
        </Button>
      </SlotModalButtonDiv>
    </div>
  );
}

const SlotModalButtonDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;
