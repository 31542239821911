import { useState } from "react";
import {
  BookingStatusEnum,
  PlatformTypeEnum,
} from "../../../helpers/constants";
import { Booking } from "../types";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
// import ReportIcon from '@mui/icons-material/Report';
// import BorderColorIcon from '@mui/icons-material/BorderColor';
import MonetizationOn from "@mui/icons-material/MonetizationOn";

import CancelIcon from "@mui/icons-material/Cancel";
import {
  statusColorMapping,
  isBookingCanBeUpdated,
  isBookingStatusCanBeUpdated,
} from "./utis";
import styled from "styled-components";

export default function BookingCard(props: {
  booking: Booking;
  setSelectedBookingForUpdate: any;
  setSelectedBookingForCancel: any;
  setSelectedBookingForConfirmation: any;
  setSelectedBookingForPaid: any;
  t: any;
}) {
  const {
    booking,
    setSelectedBookingForUpdate,
    setSelectedBookingForCancel,
    setSelectedBookingForConfirmation,
    setSelectedBookingForPaid,
    t,
  } = props;
  const color = booking.status === BookingStatusEnum.DONE ? "white" : "inherit";
  const backgroundColor = statusColorMapping[booking.status];
  const [hidden, setHidden] = useState(true);

  return (
    <BookingCardContainer
      style={{ color, backgroundColor }}
      onMouseEnter={() => setHidden(false)}
      onMouseLeave={() => setHidden(true)}
    >
      <BookingCardContent booking={booking} t={t} />
      {!hidden ? (
        <BookingCardHiddenButtons>
          {isBookingStatusCanBeUpdated(booking.status) ? (
            <>
              <CheckCircleIcon
                style={{ color: "#4FD1C5" }}
                onClick={() => setSelectedBookingForConfirmation(booking)}
              />
            </>
          ) : null}
          {isBookingCanBeUpdated(booking.status) ? (
            <>
              {/* #TODO: reactivate when we will authorize booking updates */}
              {/* <BorderColorIcon style={{ color: "#004643" }} onClick={() => setSelectedBookingForUpdate(booking)} /> */}
              {booking.platformType === PlatformTypeEnum.HOPLEISURE ? (
                <MonetizationOn
                  style={{ color: "#004643" }}
                  onClick={() => setSelectedBookingForPaid(booking)}
                />
              ) : null}
              <CancelIcon
                style={{ color: "red" }}
                onClick={() => setSelectedBookingForCancel(booking)}
              />
            </>
          ) : null}
        </BookingCardHiddenButtons>
      ) : null}
    </BookingCardContainer>
  );
}

export function BookingCardContent(props: { booking: Booking; t: any }) {
  const { booking, t } = props;
  const ticketsBookedRecap = booking.tickets
    .map((ticket) => `${ticket.numberOfUnit} ${ticket.name}`)
    .join(",");
  return (
    <>
      <div style={{ width: "40%", display: "flex", flexDirection: "column" }}>
        <div>{booking.fullName}</div>
        <div>{booking.email}</div>
        <div>{booking.phoneNumber}</div>
      </div>
      <div style={{ width: "40%", display: "flex", flexDirection: "column" }}>
        <div>{`${booking.numberOfUnitOnBooking} ${t("people(s)")} ${booking.status}`}</div>
        <div>{ticketsBookedRecap}</div>
        <div>
          {`
                ${booking.totalPaid.toFixed(2)} € 
                ${booking.discount ? `- ${booking.discount}` : ""} 
                | ${booking.totalPaid !== booking.totalPaidWithDiscount ? booking.totalPaidWithDiscount.toFixed(2) + " €" : ""} 
                ${
                  booking.paid
                    ? booking.platformType !== PlatformTypeEnum.HOPLEISURE
                      ? `${t("payedBy")}: ${booking.platformName}`
                      : t("paid")
                    : t("notPaid")
                }
              `}
        </div>
        {booking.status === BookingStatusEnum.CANCELED && booking.paid ? (
          <div>
            {`
                  ${booking.platformType === PlatformTypeEnum.HOPLEISURE ? t("toRefundHopleisure") : ""} 
                  ${booking.platformType === PlatformTypeEnum.WIDGET ? t("toRefundWidget", { orderId: booking.orderId }) : ""} 
                  ${booking.platformType !== PlatformTypeEnum.HOPLEISURE && booking.platformType !== PlatformTypeEnum.WIDGET ? t("toRefundPlatform", { platformName: booking.platformName }) : ""} 
                `}
          </div>
        ) : null}
      </div>
    </>
  );
}

const BookingCardContainer = styled.div`
  width: 100%;
  position: relative;
  min-height: 97px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const BookingCardHiddenButtons = styled.div`
  cursor: pointer;
  position: absolute;
  right: -10px;
  display: flex;
  flex-direction: column;
  background-color: white;
`;
