import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
// Home
import Agenda from "./pages/Agenda";

// Auth
import Login from "./pages/Login";
import Register from "./pages/Register";
import PasswordResetPage from "./pages/PassWordResetPage";
import Error from "./pages/Error";
import Activities from "./pages/Activities";
import Bookings from "./pages/Bookings";
import Profile from "./pages/Profile";
import Discounts from "pages/Discounts";

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={NoConnectedRoute(<Login />)} />
        <Route path="/register" element={NoConnectedRoute(<Register />)} />
        <Route path="/passwordResetPage" element={<PasswordResetPage />} />
        <Route path="/agenda" element={ConnectedRoute(<Agenda />)} />
        <Route path="/activities" element={ConnectedRoute(<Activities />)} />
        <Route path="/discounts" element={ConnectedRoute(<Discounts />)} />
        <Route path="/bookings" element={ConnectedRoute(<Bookings />)} />
        <Route path="/profile" element={ConnectedRoute(<Profile />)} />
        <Route path="/facturation" element={ConnectedRoute(<Profile />)} />
        <Route path="*" element={<Error />} />
      </Routes>
    </BrowserRouter>
  );
}

function NoConnectedRoute(Element: any) {
  const accessToken = localStorage.getItem("accessToken");
  return accessToken ? <Navigate to="/profile" /> : Element;
}

function ConnectedRoute(Element: any) {
  const accessToken = localStorage.getItem("accessToken");
  return accessToken ? Element : <Navigate to="/login" />;
}
export default Router;
