import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import styled from "styled-components";

// Styled component pour l'arrière-plan sombre
const DarkBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

// Composant React
const Loading: React.FC = () => {
  return (
    <DarkBackground>
      <CircularProgress color="inherit" />
    </DarkBackground>
  );
};

export default Loading;
