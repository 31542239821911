import React, { useState } from "react";
import { useForm, SubmitHandler, FieldValues } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { InputText } from "components/formInputs/Inputs";
import { useUser } from "contexts/UserContext";
import { Activity } from "backend/api/activities";
import { FormDiv, Column, Text } from "helpers/generalStyles";
import { createDiscount, Discount } from "backend/api/discounts";
import { Container, GenerateIcon } from "./style";
import { Button, CircularProgress, SelectChangeEvent } from "@mui/material";
import AlertMessage from "components/general/AlertMessage";
import { Message } from "helpers/helpers";
import SelectActivities from "./SelectActivities";
import { DiscountTypesEnum } from "helpers/constants";
import { queryClient } from "index";

interface CreatedDiscount {
  name: string;
  discountType: DiscountTypesEnum;
  discountNumber: number;
  code: string;
  activity_id?: number | string;
}

interface Props {
  t: any;
  activities: Activity[];
  discounts: Discount[];
}

const CreateDiscount = ({ t, activities, discounts }: Props) => {
  const { user } = useUser();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<Message>({
    type: "success",
    message: "",
  });
  const [selectedActivityIds, setSelectedActivityIds] = React.useState<
    Array<string | number>
  >([]);

  const promotionSchema = z.object({
    name: z.string(),
    discountNumber: z.number().int().min(0),
    code: z.string(),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: zodResolver(promotionSchema),
  });

  const isDiscountWithCodeAlreadyExists = (code: string) => {
    return discounts.some((discount) => discount.code === code);
  };

  const onSubmit = async (promotion: CreatedDiscount) => {
    try {
      if (user) {
        if (isDiscountWithCodeAlreadyExists(promotion.code)) {
          setAlertMessage({
            message: t("errors.codeAlreadyExists"),
            type: "error",
          });
          return;
        }
        setIsLoading(true);
        const promotions = selectedActivityIds.map((activityId) => ({
          ...promotion,
          discountType: DiscountTypesEnum.PERCENTAGE,
          clientId: typeof user.id == "string" ? parseInt(user.id) : user.id,
          activityId:
            typeof activityId == "string" ? parseInt(activityId) : activityId,
        }));

        await Promise.all(
          promotions.map((promotion) => {
            return createDiscount(promotion);
          }),
        );

        queryClient.invalidateQueries({ queryKey: ["getDiscounts"] });
        setIsLoading(false);
        setAlertMessage({ message: t("success.create"), type: "success" });
      }
    } catch (error: any) {
      setIsLoading(false);
      setAlertMessage({
        message: t("errors." + error.response.data.error),
        type: "error",
      });
    }
  };

  const generateCode = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < 12; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length),
      );
    }
    return result;
  };

  // Fonction à appeler lorsque le suffixe est cliqué
  const handleCodeGeneratorClick = () => {
    const newCode = generateCode();
    // Mettre à jour la valeur du champ 'code'
    setValue("code", newCode, { shouldValidate: true });
  };

  const handleSelectChange = (
    event: SelectChangeEvent<typeof selectedActivityIds>,
  ) => {
    const {
      target: { value },
    } = event;
    setSelectedActivityIds(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value,
    );
  };

  return (
    <Container style={{ width: "400px" }}>
      <AlertMessage
        alertMessage={alertMessage.message}
        type={alertMessage.type}
        t={t}
      />
      {isLoading ? (
        <Column center alignCenter>
          <CircularProgress color="success" />
        </Column>
      ) : (
        <FormDiv
          style={{ width: "100%", minWidth: "auto", padding: 0 }}
          onSubmit={handleSubmit(onSubmit as SubmitHandler<FieldValues>)}
        >
          <Text size="24px" weight={700} color="black">
            {t("form.create.title")}
          </Text>
          <InputText
            label={t("form.create.name")}
            name="name"
            id="coupon-name"
            control={control}
            error={errors.name?.message}
            t={t}
          />
          <InputText
            label={t("form.create.discountPercentage")}
            name="discountNumber"
            id="discountNumber"
            control={control}
            min={0}
            inputType="number"
            error={errors.discountNumber?.message}
            t={t}
          />
          <InputText
            label={t("form.create.code")}
            name="code"
            id="activation-code"
            control={control}
            error={errors.code?.message}
            t={t}
            suffix={<GenerateIcon onClick={handleCodeGeneratorClick} />}
          />
          <SelectActivities
            selectedActivityIds={selectedActivityIds.map((id) => id.toString())}
            activities={activities}
            handleSelectChange={handleSelectChange}
            errors={errors}
            t={t}
          />

          {errors.activity_id && <p>{t(errors.activity_id.message)}</p>}
          <Button
            sx={{ margin: "20px 0", width: "100%", fontSize: "22px" }}
            type="submit"
            variant="contained"
            color="primary"
          >
            {t("form.create.button")}
          </Button>
        </FormDiv>
      )}
    </Container>
  );
};

export default CreateDiscount;
