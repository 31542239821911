import { Chip } from "@mui/material";
import { categoryImageMapping } from "../../helpers/constants";
import { ActivityInformations, upsertActivity } from "backend/api/activities";
import { queryClient } from "index";
import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { arePropsEqual, toastEmitter } from "helpers/helpers";

function ActivityType(props: {
  id: number | undefined;
  informations: ActivityInformations;
  selected: boolean;
  disabled?: boolean;
  onClick: any;
}) {
  const { id, informations, selected, onClick, disabled } = props;
  const { category: type, name: title } = informations;
  const { t } = useTranslation("activities");

  const [isDeleteWarningModalOpen, setIsDeleteWarningModalOpen] =
    useState(false);

  const mutateActivity = useMutation({
    mutationFn: (data: ActivityInformations) =>
      upsertActivity({
        informations: { ...data, isDeleted: true },
        activityId: String(id) ?? null,
      }),
    onSuccess: (result: any) => {
      toastEmitter(`"${title}" ${t("activityDeletedSuccessfully")}`, "success");
      queryClient.invalidateQueries({
        queryKey: ["getActivitiesWithDisabled"],
      });
    },
    onError: (error: any) => {
      console.log(error);
      toastEmitter(`"${title}" ${t("activityDeletionError")}`, "error");
    },
  });

  const setActivityToDeleted = (informations: ActivityInformations) => {
    if (id) {
      mutateActivity.mutate(informations);
    }
  };

  const closeDeleteWarningModal = () => {
    setIsDeleteWarningModalOpen(false);
  };
  return (
    <>
      <Chip
        icon={categoryImageMapping(type)}
        label={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <strong>{title}</strong>
            {selected && (
              <DeleteIcon
                style={{
                  color: "#F95454",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setIsDeleteWarningModalOpen(true);
                }}
              />
            )}
          </div>
        }
        color={!disabled ? "success" : undefined}
        onClick={onClick}
        variant={selected ? "filled" : "outlined"}
        size="medium"
        sx={{
          margin: "10px",
          backgroundColor: !disabled
            ? selected
              ? "#2A58D"
              : "#E1F9F3"
            : "lightgray",
          color: selected ? "#FFF" : "#000000",
        }}
      />
      <Dialog open={isDeleteWarningModalOpen} onClose={closeDeleteWarningModal}>
        <DialogTitle
          style={{
            fontWeight: "700",
            color: "#2CA58D",
            fontSize: "24px",
            fontFamily: "Nunito, sans-serif",
          }}
        >
          {t("deleteActivityWarningTitle")}
        </DialogTitle>
        <DialogContent>{t("deleteActivityWarningContent")}</DialogContent>
        <DialogActions>
          <Button
            onClick={() => setActivityToDeleted(informations)}
            variant="contained"
            color="error"
          >
            {t("confirm")}
          </Button>
          <Button
            onClick={closeDeleteWarningModal}
            variant="contained"
            color="success"
          >
            {t("close")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default React.memo(ActivityType, arePropsEqual);
