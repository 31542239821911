import styled from "styled-components";

export const BookingFormBody = styled.div`
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

export const BookingFormSection = styled.div`
  width: 65%;
  min-width: 450px;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  flex: 1;
`;
