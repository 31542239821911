import axios from "axios";
import { z } from "zod";
import { getAuthorizedConfig } from "../../helpers/helpers";

const platform = z.object({
  id: z.number(),
  name: z.string(),
  createdAt: z.string(),
  platformType: z.string().nullable(),
  logo: z.array(z.string()).nullable(),
  url: z.string().nullable(),
  commissionPercent: z.string().nullable(),
  isActive: z.boolean().nullable(),
  platformClosingDate: z.string().nullable(),
  platformOpeningDate: z.string().nullable(),
  description: z.string().nullable(),
});

export type Platform = z.infer<typeof platform>;

// get all platforms
export async function getAllPlatforms(): Promise<Platform[]> {
  let config = getAuthorizedConfig("GET");
  config["url"] = `${process.env.REACT_APP_API_URL}/platforms/`;
  const response = await axios.request(config);
  const result = response.data;
  return result;
}
