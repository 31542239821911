import styled from "styled-components";
import CachedIcon from "@mui/icons-material/Cached";
import { theme } from "helpers/constants";
import { Column } from "helpers/generalStyles";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

export const Container = styled.div`
  border: 1px solid #b2b2b2;
  border-radius: 8px;
  background: #fdfdfd;
  padding: 25px;
  margin: 20px;
`;
export const GenerateIcon = styled(CachedIcon)`
  color: white;
  background-color: ${theme.color.primary};
  padding: 2px;
  border-radius: 50%;
  font-size: 20px;
  cursor: pointer;
`;

export const DiscountListContainer = styled(Column)`
  max-height: 70vh;
  overflow: auto;
  border: 1px solid #b2b2b2;
  border-radius: 8px;
  background: #fdfdfd;
  padding: 25px;
  width: 350px;
  margin: 20px;
`;

export const DiscountCardContainer = styled(Column)`
  position: relative;
  width: 100%;
`;

export const DeleteIcon = styled(DeleteForeverIcon)`
  color: #f73131;
  position: absolute;
  right: 8px;
  top: 8px;
  cursor: pointer;
`;
