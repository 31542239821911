// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rbc-toolbar {
  justify-content: flex-start;
}

.rbc-btn-group {
  margin-right: auto;
  margin-left: 10px; /* Adjust the left margin as needed */
}

.rbc-event {
  background-color: #4fd1c5;
  padding: 0 !important;
}

.rbc-event.rbc-selected {
  background-color: #4fd1c5;
}
.rbc-selected {
  background-color: #4fd1c5;
}

.rbc-event:hover {
  background-color: #054643;
  z-index: 20 !important;
  min-width: 150px !important;
}

.rbc-event:active {
  background-color: #054643;
}

.rbc-event:focus {
  background-color: #054643;
}

.rbc-row-content {
  min-height: 50px !important;
}

.rbc-timeslot-group {
  min-height: 100px !important;
}

.rbc-header {
  font-size: 20px;
  font-weight: 800;
  height: 100px;
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
}

.rbc-button-link {
  font-size: 20px;
  font-weight: 800;
}

.rbc-event-label {
  display: none !important;
}
`, "",{"version":3,"sources":["webpack://./src/helpers/css/myCalendar.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;AAC7B;;AAEA;EACE,kBAAkB;EAClB,iBAAiB,EAAE,qCAAqC;AAC1D;;AAEA;EACE,yBAAyB;EACzB,qBAAqB;AACvB;;AAEA;EACE,yBAAyB;AAC3B;AACA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;EACzB,sBAAsB;EACtB,2BAA2B;AAC7B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,aAAa;EACb,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,wBAAwB;AAC1B","sourcesContent":[".rbc-toolbar {\n  justify-content: flex-start;\n}\n\n.rbc-btn-group {\n  margin-right: auto;\n  margin-left: 10px; /* Adjust the left margin as needed */\n}\n\n.rbc-event {\n  background-color: #4fd1c5;\n  padding: 0 !important;\n}\n\n.rbc-event.rbc-selected {\n  background-color: #4fd1c5;\n}\n.rbc-selected {\n  background-color: #4fd1c5;\n}\n\n.rbc-event:hover {\n  background-color: #054643;\n  z-index: 20 !important;\n  min-width: 150px !important;\n}\n\n.rbc-event:active {\n  background-color: #054643;\n}\n\n.rbc-event:focus {\n  background-color: #054643;\n}\n\n.rbc-row-content {\n  min-height: 50px !important;\n}\n\n.rbc-timeslot-group {\n  min-height: 100px !important;\n}\n\n.rbc-header {\n  font-size: 20px;\n  font-weight: 800;\n  height: 100px;\n  display: flex;\n  align-items: center;\n  text-overflow: ellipsis;\n}\n\n.rbc-button-link {\n  font-size: 20px;\n  font-weight: 800;\n}\n\n.rbc-event-label {\n  display: none !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
