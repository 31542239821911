import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { debounce } from "@mui/material/utils";
import { useEffect } from "react";
import { FormHelperText } from "@mui/material";
import { Controller } from "react-hook-form";
import { FormBox, FormLabel } from "../../../helpers/generalStyles";
import {
  filterGooglesMapsSearchResults,
  loadScript,
} from "./AddressAutocomplete.utils";
import { PlaceType } from "./AddressAutocomplete.types";

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

const autocompleteService = { current: null };

export default function GoogleMaps(props: {
  name: string;
  label: string;
  control: any;
  disabled: boolean;
  required?: boolean;
  defaultValue?: string;
  t: any;
}) {
  const { name, label, control, disabled, required, defaultValue, t } = props;
  const [value, setValue] = React.useState<PlaceType | null>(null);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState<readonly PlaceType[]>([]);
  const loaded = React.useRef(false);

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector("head"),
        "google-maps",
      );
    }
    loaded.current = true;
  }

  const fetch = React.useMemo(() => {
    return debounce(
      (
        request: { input: string },
        callback: (results?: readonly PlaceType[]) => void,
      ) => {
        (autocompleteService.current as any).getPlacePredictions(
          {
            input: request.input,
            types: ["geocode"],
          },
          (results: any) => {
            const filteredResults = filterGooglesMapsSearchResults(results);
            callback(filteredResults);
          },
        );
      },
      400,
    );
  }, []);

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (
        window as any
      ).google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results?: readonly PlaceType[]) => {
      if (active) {
        let newOptions: readonly PlaceType[] = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }
        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <FormBox key={defaultValue}>
      <FormLabel style={{ marginLeft: "5px" }}>{`${t(label).toUpperCase()} ${
        required ? "*" : ""
      }`}</FormLabel>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => {
          const { onChange, value, ref } = field;
          return (
            <div
              style={{
                flex: 3,
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Autocomplete
                disabled={disabled}
                getOptionLabel={(option) =>
                  typeof option === "string" ? option : option.description
                }
                fullWidth
                filterOptions={(x) => x}
                options={options}
                autoComplete
                includeInputInList
                filterSelectedOptions
                value={
                  value?.description
                    ? value?.description
                    : defaultValue || value
                }
                noOptionsText="No locations"
                onChange={(event: any, newValue: PlaceType | null) => {
                  setOptions(newValue ? [newValue, ...options] : options);
                  setValue(newValue);
                  onChange(newValue?.description || newValue);
                }}
                key={name}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    value={params.inputProps.value}
                    name={name}
                    fullWidth
                    inputRef={ref}
                    variant="standard"
                  />
                )}
                renderOption={(props, option) => {
                  return (
                    <li {...props}>
                      <Grid container alignItems="center">
                        <Grid item sx={{ display: "flex", width: 44 }}>
                          <LocationOnIcon sx={{ color: "text.secondary" }} />
                        </Grid>
                        <Grid item sx={{ wordWrap: "break-word" }}>
                          <div style={{ fontWeight: "bold" }}>
                            {option.structured_formatting.main_text}
                          </div>
                          <Typography variant="body2" color="text.secondary">
                            {option.structured_formatting.secondary_text}
                          </Typography>
                        </Grid>
                      </Grid>
                    </li>
                  );
                }}
              />
              <FormHelperText style={{ color: "#d32f2f" }}>
                {error ? t(error?.message) : ""}
              </FormHelperText>
            </div>
          );
        }}
      />
    </FormBox>
  );
}
