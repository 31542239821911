import { useState } from "react";
import { EventData } from "./types";
import ReportIcon from "@mui/icons-material/Report";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import moment from "moment";
import { isSlotBookable } from "./helpers/utis";
import styled from "styled-components";
import { SpecialSlotTypeEnum } from "helpers/constants";

export default function AgendaEvent(props: {
  event: EventData;
  titleEventColorMapping: any;
  setSelectedEventForUpdate: any;
  setSelectedEventForChangeStatus: any;
  setSelectedBooking: any;
  navigate: any;
  t: any;
}) {
  const {
    event,
    titleEventColorMapping,
    setSelectedEventForUpdate,
    setSelectedEventForChangeStatus,
    setSelectedBooking,
    navigate,
    t,
  } = props;
  const [hidden, setHidden] = useState(true);

  const title = event?.data?.title;
  let backgroundColor =
    title && Object.keys(titleEventColorMapping).includes(title)
      ? titleEventColorMapping[title]
      : "#4FD1C5";
  let color = "#004643";
  const isSlotIsFull =
    event?.data?.maxCapacity !== undefined &&
    event?.data?.numberOfUnitBooked !== undefined &&
    Number(event?.data?.numberOfUnitBooked) >= Number(event?.data?.maxCapacity);
  const isSlotIsMarkAsFull =
    event?.data?.specialSlotType === SpecialSlotTypeEnum.MARK_AS_FULL;
  const isSlotIsCanceled =
    event?.data?.specialSlotType === SpecialSlotTypeEnum.UNAVAILABLE;
  if (isSlotIsCanceled) {
    backgroundColor = "lightgray";
  }

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        position: "relative",
        padding: "5px",
        backgroundColor,
        color,
      }}
      onMouseEnter={() => setHidden(false)}
      onMouseLeave={() => setHidden(true)}
    >
      <div
        style={{
          fontWeight: 0,
          fontSize: "13px",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
      >{`${moment(event.start).format("HH:mm")} - ${moment(event.end).format("HH:mm")}`}</div>

      {isSlotIsFull ? (
        <div style={{ fontWeight: 800, fontSize: "13px" }}>
          {t(`agendaEvent.full`).toUpperCase()}
        </div>
      ) : isSlotIsMarkAsFull ? (
        <div
          style={{ fontWeight: 800, fontSize: "13px" }}
        >{`${t(`agendaEvent.limitedTo`).toUpperCase()} ${event.data.numberOfUnitBooked}`}</div>
      ) : !isSlotIsCanceled ? (
        <div
          style={{ fontWeight: 800, fontSize: "13px" }}
        >{`${event.data.numberOfUnitBooked} / ${event.data.maxCapacity}`}</div>
      ) : null}

      <div style={{ fontSize: "13px" }}>{event.data.title}</div>

      {!hidden ? (
        <HiddenButtonsContainer>
          <ReportIcon
            style={{ color: "orange" }}
            onClick={() => setSelectedEventForUpdate(event)}
          />
          {isSlotBookable(event.data.specialSlotType) ? (
            <>
              {moment(event.end) >= moment() ? (
                <AddCircleOutlineIcon
                  color="secondary"
                  onClick={() =>
                    setSelectedBooking({
                      activityId: event.data.activityId,
                      start: moment(event.start).format("YYYY-MM-DD HH:mm"),
                      end: moment(event.end).format("YYYY-MM-DD HH:mm"),
                    })
                  }
                />
              ) : null}
              {moment(event.end) >= moment() ? (
                <CancelIcon
                  style={{ color: "red" }}
                  onClick={() => setSelectedEventForChangeStatus(event)}
                />
              ) : null}
            </>
          ) : (
            <CheckCircleIcon
              color="secondary"
              onClick={() => setSelectedEventForChangeStatus(event)}
            />
          )}
        </HiddenButtonsContainer>
      ) : null}
    </div>
  );
}

const HiddenButtonsContainer = styled.div`
  cursor: pointer;
  position: absolute;
  top: 0px;
  display: flex;
  background-color: white;
`;
