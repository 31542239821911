import StripeConnect from "components/stripeConnect";
import { Column, MainPageCard } from "helpers/generalStyles";
import React from "react";

const StripeConnectPage = ({ t }: { t: any }) => {
  return (
    <MainPageCard>
      <Column width="100%" center alignCenter style={{ padding: "30px 0" }}>
        <h2>{t("paymentMethodConnexion")}</h2>
        <StripeConnect />{" "}
      </Column>
    </MainPageCard>
  );
};

export default StripeConnectPage;
