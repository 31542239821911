import axios from "axios";
import { getAuthorizedConfig } from "../../helpers/helpers";

export async function stripeConnect(): Promise<string> {
  let config = getAuthorizedConfig("POST");
  config["url"] = `${process.env.REACT_APP_API_URL}/clients/stripe/connect`;
  const response = await axios.request(config);
  return response.data;
}

export interface StripeConnectVerificationOutput {
  accountCompleted: boolean;
}

export async function stripeConnectVerification(
  clientId: string | number,
): Promise<StripeConnectVerificationOutput> {
  let config = getAuthorizedConfig("GET");
  config["url"] =
    `${process.env.REACT_APP_API_URL}/clients/stripe/verification/${clientId}`;
  const response = await axios.request(config);
  return response.data;
}
