import styled from "styled-components";

export const StripeConnectButton = styled.button`
  background-color: #6772e5;
  border: none;
  border-radius: 8px;
  color: #ffffff;
  cursor: pointer;
  font-size: 20px;
  font-weight: 700;
  padding: 18px 22px;
  transition: background 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  height: fit-content;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
  &:hover {
    background-color: #7795f8;
  }
`;
