import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";

export default function Locker(props: { isOpen: boolean; onClick: any }) {
  const { isOpen, onClick } = props;
  return isOpen === false ? (
    <LockIcon
      fontSize={"small"}
      color={"primary"}
      onClick={onClick}
      style={{ position: "absolute", top: 20, right: 20, cursor: "pointer" }}
    />
  ) : (
    <LockOpenIcon
      fontSize={"small"}
      color={"secondary"}
      onClick={onClick}
      style={{ position: "absolute", top: 20, right: 20, cursor: "pointer" }}
    />
  );
}
