export function loadScript(
  src: string,
  position: HTMLElement | null,
  id: string,
) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

export const filterGooglesMapsSearchResults = (results: any) => {
  const allowedGoogleMapsResultsTypes = ["locality", "route", "premise"];

  return results?.filter((result: any) => {
    return result.types.some((ResultType: string) =>
      allowedGoogleMapsResultsTypes.some(
        (allowedGoogleMapsResultsType) =>
          ResultType === allowedGoogleMapsResultsType,
      ),
    );
  });
};
