import { z } from "zod";
import {
  dateableWithTimeString,
  getAuthorizedConfig,
} from "../../helpers/helpers";
import axios from "axios";
import { BookingStatusEnum } from "../../helpers/constants";
import { GetBookingsForActivityOutput } from "components/agenda/types";

const visitorInfos = z.object({
  fullName: z.string(),
  email: z.string(),
  phoneNumber: z.string(),
});

const postBookingItem = z.object({
  numberOfUnit: z.number(),
  priceName: z.string(),
  from: dateableWithTimeString,
  to: dateableWithTimeString,
  status: z
    .enum([
      BookingStatusEnum.BOOKED,
      BookingStatusEnum.CANCELED,
      BookingStatusEnum.PENDING,
    ])
    .optional(),
});

// postBooking
export const postBookingInput = z.object({
  params: z.object({
    activityId: z.string(),
  }),
  body: z.object({
    visitorInfos: visitorInfos,
    bookings: z.array(postBookingItem),
    source: z.enum(["HOPLEISURE", "WIDGET"]),
  }),
});

export type PostBookingInput = z.infer<typeof postBookingInput>;

export async function postBooking(
  activityId: number,
  data: PostBookingInput["body"],
): Promise<any[]> {
  let config = getAuthorizedConfig("POST");
  config["url"] =
    `${process.env.REACT_APP_API_URL}/bookings/activity/${activityId}`;
  config["data"] = data;
  const response = await axios.request(config);
  const result = response.data;
  return result;
}

// putBooking
const putBookingItem = postBookingItem.extend({
  id: z.number().nullable(),
});
export const putBookingInput = z.object({
  params: z.object({
    activityId: z.string(),
  }),
  body: z.object({
    visitorInfos: visitorInfos,
    bookings: z.array(putBookingItem),
  }),
});

export type PutBookingInput = z.infer<typeof putBookingInput>;
export type PutBookingItem = z.infer<typeof putBookingItem>;

export async function putBooking(
  activityId: number,
  data: PutBookingInput["body"],
): Promise<any[]> {
  let config = getAuthorizedConfig("PUT");
  config["url"] =
    `${process.env.REACT_APP_API_URL}/bookings/activity/${activityId}`;
  config["data"] = data;
  const response = await axios.request(config);
  const result = response.data;
  return result;
}

// putBooking status
export const putBookingAndPaidStatusInput = z.object({
  body: z.object({
    bookingIds: z.array(z.number()),
    status: z.enum([
      BookingStatusEnum.BOOKED,
      BookingStatusEnum.CANCELED,
      BookingStatusEnum.DONE,
      BookingStatusEnum.NO_SHOW,
      BookingStatusEnum.PENDING,
    ]),
    paid: z.boolean().optional(),
  }),
});

export type PutBookingAndPaidStatusInput = z.infer<
  typeof putBookingAndPaidStatusInput
>;

export async function putBookingAndPaidStatus(
  bookingIds: number[],
  status: BookingStatusEnum,
  paid?: boolean,
): Promise<any[]> {
  let config = getAuthorizedConfig("PUT");
  config["url"] = `${process.env.REACT_APP_API_URL}/bookings/statusAndPaid`;
  config["data"] = { bookingIds, status, paid };
  const response = await axios.request(config);
  const result = response.data;
  return result;
}

// getBookings
export const getBookingsInputs = z.object({
  params: z.object({
    activityId: z.string(),
  }),
  query: z.object({
    from: dateableWithTimeString,
    to: dateableWithTimeString,
  }),
});

export type GetBookingsInputs = z.infer<typeof getBookingsInputs>;

export async function getBookings(
  activityId: number,
  from: string,
  to: string,
): Promise<GetBookingsForActivityOutput> {
  let config = getAuthorizedConfig("GET");
  config["url"] =
    `${process.env.REACT_APP_API_URL}/bookings/activity/${activityId}?from=${from}&to=${to}`;
  const response = await axios.request(config);
  const result = response.data;
  return result;
}
