import axios from "axios";
import { z } from "zod";
import { getAuthorizedConfig } from "../../helpers/helpers";

// Schema for user settings related to notifications and alerts
export const userSettingsSchema = z.object({
  id: z.number().optional(),
  client_id: z.number(),
  email_booking_alert: z.boolean(),
  sms_booking_alert: z.boolean(),
  email_cancellation_alert: z.boolean(),
  sms_cancellation_alert: z.boolean(),
});

export type UserSettings = z.infer<typeof userSettingsSchema>;

// Update user settings
export async function updateClientSettings(
  userId: number | string,
  settingsData: Partial<UserSettings>,
): Promise<UserSettings> {
  let config = getAuthorizedConfig("PATCH");
  config["url"] = `${process.env.REACT_APP_API_URL}/client-settings/${userId}`;
  config["data"] = settingsData;
  const response = await axios.request(config);
  return response.data;
}
