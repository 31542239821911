import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  Button,
  TextField,
  Select,
  MenuItem,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { useEffect, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { useMutation } from "react-query";
import { z } from "zod";
import HopleisureSide from "../components/auth/HopleisureSide";
import { AuthTitle, HopleisureLink } from "../helpers/generalStyles";
import { updatePassword as apiUpdatePassword } from "../backend/api/auth";
import AlertMessage from "../components/general/AlertMessage";
import { Message } from "../helpers/helpers";
import LanguageSelector from "components/general/LanguageSelector";
import { VisibilityOff, Visibility } from "@mui/icons-material";

export default function PasswordResetPage() {
  const { t, i18n } = useTranslation("auth");
  const navigate = useNavigate();
  const [language, setLanguage] = useState("fr");
  const [alertMessage, setAlertMessage] = useState<Message>({
    type: "success",
    message: "",
  });

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [i18n, language]);
  const supabaseAccessToken =
    new URLSearchParams(window.location.hash).get("#access_token") || "";
  const supabaseRefreshToken =
    new URLSearchParams(window.location.hash).get("refresh_token") || "";

  return (
    <Page>
      <Container>
        <HopleisureSide />
        <FormCard>
          <AlertMessage
            alertMessage={alertMessage.message}
            setAlertMessage={setAlertMessage}
            type={alertMessage.type}
            t={t}
          />
          <LanguageSelector />
          {PwdResetForm(
            supabaseAccessToken,
            supabaseRefreshToken,
            setAlertMessage,
            navigate,
            t,
          )}
        </FormCard>
      </Container>
    </Page>
  );
}

function PwdResetForm(
  supabaseAccessToken: string,
  supabaseRefreshToken: string,
  setAlertMessage: any,
  navigate: any,
  t: any,
) {
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordVerification, setShowPasswordVerification] =
    useState(false);
  const updatePasswordFormInput = z
    .object({
      password: z.string().min(8),
      passwordVerification: z.string().min(8),
    })
    .refine((body: any) => body.password === body.passwordVerification, {
      path: ["passwordVerification"],
      message: t("password_mismatch"),
    });

  type UpdatePasswordFormInput = z.infer<typeof updatePasswordFormInput>;

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(updatePasswordFormInput),
    defaultValues: {
      password: "",
      passwordVerification: "",
    },
  });

  const mutateSignup = useMutation({
    mutationFn: async (data: UpdatePasswordFormInput) =>
      apiUpdatePassword({ ...data, supabaseAccessToken, supabaseRefreshToken }),
    onSuccess: (result) => {
      // we could also set the accessToken (which is in result)
      // in the localstorage here if we prefer to direct connect the user
      // instead of redirect him to login page
      setAlertMessage({
        type: "success",
        message: t("resetPasswordSuccessMessage"),
      });
      setTimeout(() => {
        navigate("/login");
      }, 3500);
    },
    onError: (error: any) => {
      console.log(error);
      setAlertMessage({
        type: "error",
        message: t(error?.response?.data?.error),
      });
    },
  });
  const onSubmit: SubmitHandler<any> = (data: UpdatePasswordFormInput) => {
    mutateSignup.mutate({
      password: data.password,
      passwordVerification: data.passwordVerification,
    });
  };

  return (
    <FormDiv onSubmit={handleSubmit(onSubmit)}>
      <AuthTitle>{t("passwordResetTitle").toUpperCase()}</AuthTitle>
      <Controller
        name="password"
        control={control}
        render={({ field }) => (
          <TextField
            fullWidth={true}
            id="password"
            label={t("password")}
            type={showPassword ? "text" : "password"}
            variant="standard"
            error={Boolean(errors.password)}
            helperText={errors.password ? t(errors?.password.type) : ""}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            {...field}
          />
        )}
      />
      <Controller
        name="passwordVerification"
        control={control}
        render={({ field }) => (
          <TextField
            fullWidth={true}
            id="passwordVerification"
            label={t("passwordVerification")}
            type={showPasswordVerification ? "text" : "password"}
            variant="standard"
            error={Boolean(errors.passwordVerification)}
            helperText={
              errors.passwordVerification
                ? t(errors?.passwordVerification.type) === "custom"
                  ? errors?.passwordVerification.message
                  : t(errors?.passwordVerification.type)
                : ""
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() =>
                      setShowPasswordVerification(!showPasswordVerification)
                    }
                    edge="end"
                  >
                    {showPasswordVerification ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            {...field}
          />
        )}
      />
      <HopleisureLink
        style={{ width: "100%", textAlign: "right" }}
        onClick={() => navigate("/login")}
      >
        {" "}
        {t("goBackToLogin")}{" "}
      </HopleisureLink>
      <Button fullWidth={true} variant="contained" type="submit">
        {t("passwordResetButton")}
      </Button>
    </FormDiv>
  );
}

const Page = styled.div`
  display: flex;
  height: 100vh;
  position: relative;
  background-color: #054643;
`;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  padding: 30px;
  gap: 30px;
`;

const FormCard = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 3;
  background-color: white;
  height: 100%;
  border-radius: 20px;
`;

const FormDiv = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  min-width: 400px;
  gap: 30px;
`;
